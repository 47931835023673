@import '../../mainLayout/variables.scss';

.bundle {
    .contentFilterSearch {
        width: 15rem;
        padding: 1.5rem;
        position: fixed;
        height: 96vh;
        overflow: auto;
        background-color: #e5e5e5c4;
        margin: 0 0 0 5px;

        .itemForSearch {
            margin-bottom: 0.7rem;
            .btnAround {
                background-color: $medium-gray;
                border-radius: 50px;
                border: none;
                padding: 10px;
                margin: 5px;
                color: white;

                &.selected {
                    background-color: $blue3;
                }
            }
        }

        .myAccordion {
            margin: 0 !important;
            padding: 0 !important;
            border-radius: 0 !important;
            background: none !important;
            box-shadow: none !important;
            &::before {
                background: none;
            }
            .MuiAccordionSummary-content {
                margin: 12px 0 !important;
            }
            .MuiAccordionSummary-root {
                padding: 0 3px;
                min-height: 48px;
            }
            .MuiAccordionDetails-root {
                padding: 0;
                display: block;
            }
        }
    }

    .contentUser {
        height: unset;
        padding: 1.5rem 0 0 0;
        margin-left: 16rem;
    }

    .icon {
        font-size: 20px;
        text-align: center;
        &.bundleIconOrange { 
            i {
                color: $orange !important; 
            }
        }
        &.bundleIconRed { 
            i {
                color: $red !important; 
            }
        }
    }
    .bundleLabel {
        color: black !important;
    }

    .action {
        i {
            margin-right: 10px;
        }
    }

    .tabExpanableVpnBoxStock {
        margin: 0 60px 10px 60px !important;
        padding: 15px;
        border: 1px solid #5ad05a;
        box-shadow: 1px 1px 11px #80808094;
        border-top: none;
        border-radius: 0px 0px 10px 10px;
    }
}

@media(max-width:768px){
    .bundle {
        .contentFilterSearch {
            width: 100%;
            height: unset;
            position: unset;
            margin: unset;
            overflow-y: unset;
            display: block;
            padding: 4rem 2rem;
            background-color: #e5e5e5c4;
        }

        .contentUser {
            display: block;
            height: unset;
            width: 100%;
            margin-left: unset;
            position: unset;
        }
    }
}
