@import "../../mainLayout/variables.scss";

.promoDesktop {
    .contentFilterSearch {
        width: 15rem;
        position: fixed;
        height: 96vh;
        overflow: auto;
        padding: 1.5rem;
        background-color: #e5e5e5c4;
        margin: 0 0 0 5px;

        .itemForSearch {
            margin-bottom: 0.7rem;
            .btnAround {
                background-color: $medium-gray;
                border-radius: 50px;
                border: none;
                padding: 10px;
                margin: 5px;
                color: white;

                &.selected {
                    background-color: $blue3;
                }
            }
        }

        .myAccordion {
            margin: 0 !important;
            padding: 0 !important;
            border-radius: 0 !important;
            background: none !important;
            box-shadow: none !important;
            &::before {
                background: none;
            }
            .MuiAccordionSummary-content {
                margin: 12px 0 !important;
            }
            .MuiAccordionSummary-root {
                padding: 0 3px;
                min-height: 48px;
            }
            .MuiAccordionDetails-root {
                padding: 0;
                display: block;
            }
        }
    }

    .contentPromo {
        height: unset;
        padding: 1.5rem 0 0 0;
        margin-left: 16rem;
    }

    .rdt_TableHeadRow {
        > div:first-child {
            display: flex !important
        }
        > div:nth-child(2) {
            display: none !important
        }
    }

    .rdt_TableRow {
        // Rimuove la sezione con il bottone espandibile della riga
        > div:nth-child(2) {
            display: none;
        }
    }
    .rdt_TableRow {
        // Rimuove la sezione con il bottone espandibile della riga
        > div:first-child {
            display: flex !important
        }
    }
    .moreProd {
        text-decoration: underline;
        font-style: italic;
    }
}

@media(max-width:768px){
    .promoDesktop{
        display: block;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .contentFilterSearch {
            width: 100%;
            height: unset;
            position: unset;
            margin: unset;
            overflow-y: unset;
            display: block;
            padding: 4rem 2rem;
            background-color: #e5e5e5c4;
        }
        .contentPromo {
            display: block;
            height: unset;
            width: 100%;
            margin-left: unset;
            position: unset;
            overflow-y: hidden;
            padding: 2rem;
        }
    }
}