$blue: #1b76d3;
$blue2: #1269db;
$blue3: #1572e8;
$font-sidebar: #8d9498;
$light-blue: #1b77d3ce;
$dark-gray: #3f3f3f;
$medium-gray: #808080ad;
$light-gray: #e5e5e5c4;
$orange: #ff8800;
$green: #4caf50;
$red: #d32f2f;
$yellow: yellow;
$shadow: #9da5ab;
$white: #ffffff;
$background-dashboard: #f9fbfd;
$box-shadow-dashboard: 2px 6px 15px 0px rgba(69, 65, 78, 0.20);