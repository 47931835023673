@import "../../mainLayout/variables.scss";

.modal {
    .modal-content {
        border: none;

        .modal-header {
            background-color: $blue;
            color: $white;

            .close {
                color: white;
                opacity: 1;
            }
        }

        .modal-body {
            min-height: 110px;
            height: auto;

            &.maxHeigthScroll {
                max-height: 78vh;
                overflow-y: scroll;
            }
        }

        .modal-footer {
            border-top: none;
        }
    }
}

.modalPlayer {
    .modal-body {
        .radioCheck {
            margin: 8px;
        }
    }
}

.modalChangeLog,
.modalRenderStock,
.modalBundle {
    .miniLegend {
        color: red;
        font-style: italic;
        font-size: 13px;
    }
}

.modalMail.modal-dialog,
.modalUser.modal-dialog,
.modalGroup.modal-dialog,
.modalRenderStock.modal-dialog,
.modalVpnStock.modal-dialog,
.modalVpn.modal-dialog,
.modalPlaylist.modal-dialog,
.modalActiveJob.modal-dialog,
.modalChangeLog.modal-dialog,
.modalPlayer.modal-dialog {
    max-width: 700px;
}

.modalCoupon.modal-dialog {
    max-width: 1000px;

    .downLabel {
        padding-top: 15px;
        margin-bottom: -15px;
    }

    .downSelect {
        padding-top: 10px;
    }
}

.modalDiagnosticsVpn {
    &.modal-dialog {
        max-width: 1000px;
    }

    .modal-body {
        max-height: 80vh;
        min-height: 300px !important;
        overflow-y: auto;
    }

    .progress,
    .buttons {
        margin-bottom: 1rem;
    }

    .loading {
        position: absolute;

        .contentLoading {
            margin: 0;
        }
    }
}

.des {
    font-style: italic;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 5px;
    width: 200px;
    font-size: 12px;
}

.modalUser {
    .choose-form {
        padding: 5px 30px 0 30px;
    }
}

.autoCompleteDevice {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: aliceblue;
    border-radius: 5px;
    font-style: italic;

    li {
        margin: 0 0 12px 12px;

        &:nth-child(1) {
            margin-top: 3px;
        }

        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        &:hover {
            cursor: pointer;
            font-weight: bold;
            transition: all .2s;
        }
    }
}

.autoCompleteDeviceCountry {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: aliceblue;
    border-radius: 5px;
    font-style: italic;
    overflow-y: scroll;
    height: 15vh;

    li {
        margin: 0 0 12px 12px;

        &:nth-child(1) {
            margin-top: 3px;
        }

        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        &:hover {
            cursor: pointer;
            font-weight: bold;
            transition: all .2s;
        }
    }
}

.multiple-value-text-input .multiple-value-text-input-item {
    padding: 5px;
    background: aliceblue !important;
    border-radius: 5px;
    border: 2px solid cadetblue;
    margin: 2px;
    color: brown;
}

.multiple-value-text-input input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.multiple-value-text-input input:disabled,
.multiple-value-text-input input[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.multiple-value-text-input-item {
    display: inline-block;
}

.modalShowDynamicData.modal-dialog {
    max-width: 700px;

    .modal-body {
        >.row:nth-child(odd) {
            background-color: #80808026;
        }

        >.row {
            padding: 5px 0 5px 0;

            .subRow {
                background-color: antiquewhite;
            }
        }
    }

    .elem {
        word-break: break-all;
    }
}

.modalPricing {
    max-width: 900px !important;

    .checkBoxContain {
        margin: 2vh 0;

        .checkBoxGroup {
            display: flex;

            .MuiFormGroup-root {
                margin: 0 auto;
            }
        }
    }
}

.modalRole {
    max-width: 40% !important;

    .MuiAccordion-root.Mui-expanded {
        margin: 0;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
        background: none !important;
    }

    .col-8:nth-child(odd) {
        background-color: #f4f3f3;
    }

    .MuiTypography-body1 {
        font-size: 20px;
    }

    .MuiFormControlLabel-root {
        .MuiTypography-body1 {
            font-size: 16px;
        }
    }

    .MuiFormControl-root {
        margin-top: 1vh !important;
    }

    .css-yk16xz-control {
        border: none !important;
        border-bottom: 1px dotted !important;
        border-radius: 0 !important;
    }

    .form-label {
        font-size: 12px;
        color: #e4e4e4;
        margin-bottom: 0;
    }
}

@keyframes color_change_for_track {
    from {
        background-color: #ffad1449;
    }

    to {
        background-color: #ffdd9f6e;
    }
}

.modalVpnStock,
.modalCoupon {
    h6 {
        margin-bottom: 0;
    }

    .associateTracking {
        >div {
            margin-bottom: 15px;
        }

        .classForTracking {
            input {
                animation-name: color_change_for_track;
                animation-duration: 0.5s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
        }
    }

    .associateTracking2 {
        border-top: 1px solid $light-gray;
        padding-top: 10px;

        >div {
            margin-bottom: 15px;
        }
    }

    .createPayment {
        margin-top: 1rem;
        margin-bottom: 0;
        // font-weight: normal;
        font-style: italic;
        color: $green;
        border-bottom: 1px solid $light-gray;
    }

    .createDevice {
        margin-top: 1rem;
        margin-bottom: 0;
        // font-weight: normal;
        font-style: italic;
        color: $orange;
        border-bottom: 1px solid $light-gray;
    }
}

.modalPayment,
.modalRefundPayment,
.modalCoupon {
    max-width: 50% !important;

    .rowProduct {
        box-shadow: 0px 1px 6px 0px $medium-gray;
        border-radius: 5px;
        margin: 0 1px 10px 1px;
        padding: 5px 0 10px;

        .iconCancel {
            position: absolute;
            color: $red;
            border-radius: 25px;
            background: white;
            border: 1px solid $red;
            width: 25px;
            text-align: center;
            font-size: 15px;
            margin: 15px auto 0 auto;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .titleTable {
        font-style: italic;
        border-bottom: 1px solid #d3d3d6;
    }

    .viewRefunded {
        margin-bottom: 20px;
    }

    .elemRefund {
        margin: 5px 00;

        &:nth-child(odd) {
            background: white;
        }

        &:nth-child(even) {
            background: #0000ff0f;
        }

        .product {
            font-weight: bold;
        }
    }
}

.promoByCountry {
    font-size: 20px;
    margin: 1vh 1vh 0 1vh;

    .promoByCountryName {
        font-weight: bold;
        padding-right: 1vw;
    }
}


.text {
    text-align: right;
}

.positionSwitch {
    margin-left: 16px;
}

.hrForPlus {
    border-top: 1px solid $green;
}

.iconPlus {
    color: white;
    border-radius: 25px;
    background: $green;
    width: 24px;
    text-align: center;
    font-size: 16px;
    margin: auto;

    &:hover {
        cursor: pointer;
    }
}

@media(max-width: 780px) {
    .modalRole {
        max-width: 100% !important;
    }
}

@media(max-width: 760px) {
    .text {
        text-align: left;
    }

    .positionSwitch {
        margin-left: -15px !important;
    }

    .modalVpnStock {
        max-width: 100% !important;
    }
}