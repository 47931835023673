@import "../../mainLayout/variables.scss";

.btn-group {
    > .btn {
        background: none;
        color: #838383;
        border: none;
        &::after {
            content: none;
        }
        &:hover {
            background: none !important;
        }
    }

    .dropdown-menu {
        min-width: 15rem;
        .row {
            > div {
                padding: 0;
            }
            .col-9 {
                padding-top: 3px;
                font-size: 14px;
            }
        }
        button, a {
            margin: 2px 0px 2px 0;
            &:nth-child(odd) {
                background-color: #cfe7ff5b;
            }
            &:hover {
                background-color: #bbddffe0;
            }
            .icon {
                font-size: 20px;
                text-align: center;
            }
            .changeDevice {
                i {
                    color: #1b76d3 !important;
                }
            }
            .refundPayment {
                i {
                    color: #ffc107 !important;
                }
            }
            .deletePayment {
                i {
                    color: red !important;
                }
            }
            .downloadPayment {
                i {
                    color: orange !important;
                }
            }
            .bollaPayment {
                i {
                    color: blue !important;
                }
            }
            .label {
                color: black !important;
            }
        }
    }
}

.blockProduct {
    border: 1px solid #80808052;
    border-radius: 15px;
    margin: 0 10px 10px 10px !important;
    padding: 10px 0 10px 0;
    box-shadow: 1px 2px 7px #8080809c;
    > div {
        margin-bottom: 8px;
    }
    .btn {
        i {
            margin-right: 5px;
        }
    }
}

.clickForPlayer, .clickForUser {
    font-size: 18px;
    color: #1b76d3;
    &:hover {
        cursor: pointer;
    }
}

.iconTrial,
.iconWait,
.iconLifetime,
.iconRefund,
.iconPartialRefund,
.iconSub,
.iconNotSub {
    font-size: 15px;
    margin-right: 3px;
    margin-left: 10px;
}

.iconTrial {
    color: #f35555;
}

.iconLifetime {
    color: #00d200;
}

.iconRefund {
    color: #fa3a3a;
}

.iconSub {
    color: $blue;
}

.iconNotSub,
.iconWait,
.iconPartialRefund {
    color: orange;
}