@import "../../mainLayout/variables.scss";

/* SIDEBAR DESKTOP APERTA */
.sidenav---sidenav---_2tBP {
    overflow: auto;
    background-color: $white;
    box-shadow: 4px 4px 10px 10px rgba(69, 65, 78, 0.06);
    position: fixed;
    z-index: 110 !important;

    &.sidenav---expanded---1KdUL {
        min-width: 232px;

        .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47>.sidenav---navitem---9uL5T {
            padding: 0 10px 0 32px;
            font-size: 12px;
        }
    }

    .sidebar-header {
        background-color: $blue3;
        box-shadow: 0px 0px 5px 5px rgba(18, 23, 39, 0.05);
        text-align: center;
        padding: 0;
        height: 50px;

        .logo {
            height: 25px;
            margin-top: 12px;
        }
    }

    .sidebar {
        margin-top: 20px;

        .item {
            //casella della sidebar (non selezionata)
            padding: 0 8px;

            >div {
                &::after {
                    border-radius: 5px;
                }
            }

            &:hover>.sidenav---navitem---9uL5T {

                //passaggio col mouse su elemento sidebar
                &::after {
                    background-color: gray;
                    transition: all .3s;
                }

                .sidenav---navicon---3gCRo i,
                //icone
                .sidenav---navtext---1AE_f {
                    //testo vicino icone
                    color: $dark-gray;
                }
            }

            .sidenav---navitem---9uL5T {
                >div {
                    //testo vicino icone
                    color: $font-sidebar;
                    font-size: 14px;
                    transition: all .3s;

                    i {
                        //icone
                        font-size: 20px;
                        transition: all .3s;
                        color: $font-sidebar;
                    }
                }
            }

            .sidenav---sidenav-subnav---1EN61 {
                //casella del sottomenu dropdown
                background-color: $white;
                // margin-left: 10px;
                min-width: auto;
                transition: all .3s;

                .dot {
                    margin: 8px;
                }

                .subItem {
                    margin: 5px 10px;
                    width: 195px;

                    .sidenav---navitem---9uL5T {
                        >div {
                            //testo vicino icone nel subItem
                            padding-left: 15px;
                        }
                    }

                    >div>div:hover {
                        //passaggio col mouse su testo del sottomenu
                        color: $font-sidebar;
                        background-color: $light-gray;
                        transition: all .3s;
                        border-radius: 5px;
                    }

                    &.sidenav---selected---1EK3y {

                        // casella del sottomenu selezionata
                        >div>div {
                            // solo la parte di testo
                            background-color: $light-gray;
                            border-radius: 5px;
                            color: gray !important;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }

        .sidenav---selected---1EK3y {

            //casella della sidebar selezionata
            >div {
                border-radius: 5px;
                background-color: $blue3;
            }

            &:hover>.sidenav---navitem---9uL5T {

                //passaggio col mouse su elemento sidebar
                .sidenav---navicon---3gCRo i,
                //icone
                .sidenav---navtext---1AE_f {
                    //testo vicino icone
                    color: $white;
                }
            }
        }
    }

    &.addSpaceForNoticeInSidenav {
        top: 95px !important;
    }
}

/* SIDEBAR DESKTOP CHIUSA */
.sidenav---collapsed---LQDEv {
    .sidebar {
        .item {
            >div .sidenav---navicon---3gCRo {
                margin-right: 0;
            }
        }
    }
}

.sidebarMobile {
    .logo {
        background-color: $blue2;
        margin-bottom: 10px;

        .infoVersion {
            color: white;
            width: 30%;
            display: inline;
            font-size: 12px;
        }

        img {
            width: 58%;
            padding: 1rem;
        }
    }

    .header {
        background-color: $blue2;
        margin: 0 0 20px 0 !important;
        padding: 15px 0 0 33px !important;
        height: 65px;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;

        img {
            height: 30px;
            width: auto;
        }

        .version {
            color: $white;
            font-size: 12px;
            float: right;
            margin: 16px 9px 0 0;
        }
    }

    .item {
        margin: 2px 15px;
        padding: 10px 1px;
        color: $font-sidebar;

        &.selected {
            background-color: $blue3;
            border-radius: 5px;
            color: white;
            font-weight: bold;
        }

        &.indent {
            padding-left: 30px;
        }

        .icon {
            display: inline-block;
            width: 50px;
            font-size: 18px;
            text-align: center;
        }

        .text {
            font-size: 16px;
            display: inline-block;
        }
    }

    label {
        padding-left: 30px;
        font-size: 16px;
        color: $blue2;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-top: 10px;
    }

    .row {
        padding: 0;
        margin: 0;
        margin-bottom: 12px;

        >div {
            padding: 3px;
            margin: 0;

        }

        .similHref {
            color: $blue;
            text-decoration: none;
            font-size: 15px;
        }

        i {
            font-size: 20px;
        }

        .red {
            color: $red;
        }

    }

    >div:nth-child(2) {
        width: 78%;
        z-index: 120;
    }

    .btn {
        z-index: 990;
        border: 1px solid black;
        position: fixed;
        top: 2%;
        left: 5%;
    }

    .buttonSections p {
        background-color: $blue;
        color: $white;
        padding-left: 1rem;
    }
}

.pad-0 {
    padding: 0 !important;
}

@media(max-width: 768px) {
    .sidenav---sidenav---_2tBP {
        display: none !important;
    }
}

@media(min-width: 768px) {
    .sidebarMobile {
        display: none !important;
    }
}

@media(max-width: 440px) {
    .sidebarMobile {
        .btn {
            z-index: 990;
            border: unset;
            color: transparent;
            position: absolute;
            top: 2%;
            left: 5%;
        }
    }

    .onlyReseller {
        font-size: 12px;
    }
}