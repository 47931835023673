@import "../../mainLayout/variables.scss";

.header {
    position: fixed;
    top: 0;
    background-color: #114578;
    height: 50px;
    line-height: 40px;
    padding: 0.5rem 3% 0 2%;
    width: 100%;
    transition: all 0.2s;
    z-index: 2;
    color: white;

    .deactiveForMobile {
        .userArea {
            padding-left: 2rem;
            .infoVersion {
                font-weight: bold;
                padding: 0 1rem 0 0;
            }

            .userMail {
                font-weight: bold;
                padding: 0 1rem;
            }

            .userRole {
                font-style: italic;
                padding: 0 1rem;
            }

            .logo {
                height: 25px;
                margin: -11px 11px 0px -11px;
            }
        }
    }

    .activeForMobile {
        .btnSidebar {
            margin-top: 5px;

            i {
                font-size: 30px;
                color: $orange;
            }
        }

        .logo-image {
            height: 40px;
            width: auto;
        }
    }
}

@media(max-width: 1100px) {
    .header .btnRight .btnNav.name {
        margin-top: -12px;
        width: 84%;
    }
}

@media(max-width: 768px) {
    .contentMain {
        margin-left: 0 !important;

        .header {
            height: 60px;

            .deactiveForMobile {
                display: none !important;
            }

            .btnRight {
                padding-top: 0;
            }

            .activeForMobile {
                .userAreaSmart {
                    font-size: 35px;
                }

                .text-right {
                    text-align: left !important;
                }
                .logo-image {
                    height: auto;
                    width: 10rem;
                    margin-left: 1rem;
                }
            }
        }

        .subHeader {
            height: 80px;

            .deactiveForMobile {
                display: none !important;
            }

            .activeForMobile {
                display: block;
            }
        }
    }
}

@media(min-width: 769px) {
    .activeForMobile {
        display: none !important;
    }

    .header .btnRight {
        text-align: right;
        padding-top: 13px !important;
        padding-right: 45px;
    }
}

@media(width: 375px) and (height: 667px) {
    .contentMain {
        .mybadge {
            left: 72vw;
        }
    }
}

@media(max-width: 360px) {
    .contentMain {
        .mybadge {
            left: 72vw;
        }
    }
}

@media(max-width: 320px) {
    .contentMain {
        .mybadge {
            left: 70vw;
        }
    }
}