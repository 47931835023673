@import "../../mainLayout/variables.scss";

.stockDesktop{
    .icon {
        font-size: 1.3rem;
        &.B { color: $orange }
        &.L { color: $green }
        &.U { color: $red }
    }

    .contentFilterSearch {
        width: 15rem;
        padding: 1.5rem;
        position: fixed;
        height: 96vh;
        overflow: auto;
        background-color: #e5e5e5c4;
        margin: 0 0 0 5px;
        .itemForSearch {
            margin-bottom: 0.7rem;
            .btnAround {
                background-color: $medium-gray;
                border-radius: 50px;
                border: none;
                padding: 10px;
                margin: 5px;
                color: white;

                &.selected {
                    background-color: $blue3;
                }
            }
        }

        .myAccordion {
            margin: 0 !important;
            padding: 0 !important;
            border-radius: 0 !important;
            background: none !important;
            box-shadow: none !important;
            &::before {
                background: none;
            }
            .MuiAccordionSummary-content {
                margin: 12px 0 !important;
            }
            .MuiAccordionSummary-root {
                padding: 0 3px;
                min-height: 48px;
            }
            .MuiAccordionDetails-root {
                padding: 0;
                display: block;
            }
        }
    }

    .contentUser {
        height: unset;
        margin-left: 16rem;

        .contentBoxStatistics {
            background-color: #0039d82b;
            padding: 1rem;
            margin: 0 0 1rem 0;
            border-radius: 0 0 15px 15px;
        }

        .mybadge {
            margin: -3px 0 0 8px !important;
        }

        .tabExpanableVpnStock {
            margin: 0 60px 10px 60px !important;
            padding: 15px;
            border: 1px solid #5ad05a;
            box-shadow: 1px 1px 11px #80808094;
            border-top: none;
            border-radius: 0px 0px 10px 10px;

            .MuiAccordion-root {
                margin: 0 0 16px 0 !important;
                .Mui-expanded {
                    margin: 0 !important;
                }
            }

            .MuiPaper-elevation1 {
                box-shadow: 0px 0px 3px 2px rgb(69 65 78 / 20%);
            }

            .MuiAccordionDetails-root {
                display: inherit;
                // padding: 0;
            }

            h5 {
                font-size: 1.2rem;
                &.green { color: $green; }
                &.orange { color: $orange; }
                &.red { color: $red; }
            }
        }
    }
}

@media(max-width:768px){
    .stockDesktop {
        .contentFilterSearch {
            width: 100%;
            height: unset;
            position: unset;
            margin: unset;
            overflow-y: unset;
            display: block;
            padding: 4rem 2rem;
            background-color: #e5e5e5c4;
        }

        .contentUser {
            display: block;
            height: unset;
            width: 100%;
            margin-left: unset;
            position: unset;
            overflow-y: unset;

            .mb1 {
                margin-bottom: 1rem !important;
            }
        }
    }
}