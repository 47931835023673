.role {
    padding: 2rem 1rem 0 3rem; 

    .rdt_TableRow {
        // Rimuove la sezione con il bottone espandibile della riga
        > div:nth-child(2) {
            display: none;
        }
    }
}

.modalRole {
    min-height: 500px;
    .modal-content {
        min-height: 500px;
        .modal-body.maxHeigthScroll {
            overflow-y: auto;
        }
    }
}

@media(max-width: 500px) {
    .role .title {
        padding-top: 2rem;
    }
}
