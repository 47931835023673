@import "../../mainLayout/variables.scss";

.userInDetail {
    .contentFilterSearch {
        width: 15rem;
        position: fixed;
        height: 96vh;
        overflow: auto;
        padding: 1.5rem;
        background-color: #e5e5e5c4;
        margin: 0 0 0 5px;

        .itemForSearch {
            margin-bottom: 0.7rem;
        }
    }

    .contentUser {
        height: unset;
        padding: 5rem 0 0 0;
        margin-left: 16rem;

        .myNavTabs {
            text-align: center;
            border-bottom: 1px solid $blue3;
            transition: all .2s;
            margin-top: -4rem;
            z-index: 100;
            background: $background-dashboard;

            .nav-link{
                padding-left: 30px;
                padding-right: 30px;
                &.active {
                    border-color: $blue3 $blue3 #fff !important;
                    border-radius: 20px 20px 0 0;
                }
                &:hover {
                    border-color: #fff #fff $blue3 #fff;
                }
            }

            .navIcon {
                .labelNav {
                    padding: 0;
                    margin: 0;
                    color: black !important;
                }
                .fa-play-circle {
                    color: #37c3ec;
                    font-size: 30px;
                }
                .fa-key {
                    color: #054a9f;
                    font-size: 30px;
                }
                .fa-chromecast {
                    color: #4548fc;
                    font-size: 30px;
                }
                .fa-gift {
                    color: #37c3ec;
                    font-size: 30px;
                }
                .fa-money-bill {
                    color: #5ad05a;
                    font-size: 30px;
                }
                .fa-user-shield {
                    color: #ff5a5a;
                    font-size: 30px;
                }
                .fa-user-lock {
                    color: #ec9737;;
                    font-size: 30px;
                }
            }
        }
    }
}

@media(max-width:768px){
    .userInDetail {
        .contentFilterSearch {
            width: 100%;
            height: unset;
            position: unset;
            margin: unset;
            overflow-y: unset;
            display: block;
            padding: 4rem 2rem;
            background-color: #e5e5e5c4;
        }

        .contentUser {
            display: block;
            height: unset;
            width: 100%;
            margin-left: unset;
            position: unset;
            overflow-y: unset;
            .myNavTabs {
                margin-bottom: 2rem;
                padding-top: 5rem;
            }
        }
    }
}
