@import "../../mainLayout/variables.scss";

.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    display: none;
}

.react-bootstrap-table-pagination-list.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    display: inline-block;
}

.MuiFormControl-marginNormal {
    margin: 0 !important;
}

.MuiFormControl-root {
    width: 100%;
    .row {
        margin-bottom: 1%;
    }
}

.userDesktop{
    .contentFilterSearch {
        width: 15rem;
        padding: 1.5rem;
        position: fixed;
        height: 96vh;
        overflow: auto;
        background-color: #e5e5e5c4;
        margin: 0 0 0 5px;

        .itemForSearch {
            margin-bottom: 0.7rem;
            .btnAround {
                background-color: $medium-gray;
                border-radius: 50px;
                border: none;
                padding: 10px;
                margin: 5px;
                color: white;

                &.selected {
                    background-color: $blue3;
                }
            }
        }

        .myAccordion {
            margin: 0 !important;
            padding: 0 !important;
            border-radius: 0 !important;
            background: none !important;
            box-shadow: none !important;
            &::before {
                background: none;
            }
            .MuiAccordionSummary-content {
                margin: 12px 0 !important;
            }
            .MuiAccordionSummary-root {
                padding: 0 3px;
                min-height: 48px;
            }
            .MuiAccordionDetails-root {
                padding: 0;
                display: block;
            }
        }
    }

    .contentUser {
        height: unset;
        padding: 1.5rem 0 0 0;
        margin-left: 16rem;
    }
}

@media(max-width:768px){
    .userDesktop {
        .contentFilterSearch {
            width: 100%;
            height: unset;
            position: unset;
            margin: unset;
            overflow-y: unset;
            display: block;
            padding: 4rem 2rem;
            background-color: #e5e5e5c4;
        }

        .contentUser {
            display: block;
            height: unset;
            width: 100%;
            margin-left: unset;
            position: unset;
            overflow-y: unset;
        }
    }
}