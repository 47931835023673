@import 'variables';
@import '../common/header/style.scss';
@import '../common/sidebar/style.scss';

html, body, #root, .appContainer, .main, .contentMain {
    min-height: 100vh;
    height: auto;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 15px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $medium-gray; 
}

.main {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: $background-dashboard;
    
    .mar-0 {
        margin: 0;
    }

    .pad-0 {
        padding: 0;
    }

    .mainLayout {
        padding: 3rem 1rem 0 1rem;
    }

    .contentMain {
        margin-left: 75px;
        transition: all .2s;
        height: 100%;

        &.expand{
          margin-left: 200px;
          transition: all .2s;
        }

        &.miniExpand{
          margin-left: 63px;
          transition: all .2s;
        }

        &.onlyLogin {
            margin-left: 0;
        }
    }
}

.margin-top-1 {
    margin-top: 1%;
}

.rdt_TableRow {
    // Rimuove la sezione con il bottone espandibile della riga
    > div:first-child {
        display: none;
    }
    
    &:hover {
        background-color: #1b76d39e !important;
        color: white;
        > div {
            .fas.fa-copy, span i, i {
                color: white !important;
            }
        }
        .btn-group {
            button i {
                color: white;
            }
        }
    }
}

.rdt_TableHeadRow {
    // Rimuove la sezione con il bottone espandibile della riga
    > div:first-child {
        display: none;
    }
}

// perche il dropdown con un solo item viene nascosto
.rdt_TableBody {
    min-height: 50vh;
}

// perche il dropdown con un solo item viene nascosto
.rdt_Table {
    height: auto !important;
}

.grecaptcha-badge {
    visibility: collapse !important;
}

.mybadge{
    padding: 1px 12px 1px 12px;
    margin: 6px 0px 0px 15px;
    font-size: 17px !important;
    background-color: #008000b0;
    color: white;
    position: absolute;
    border-radius: 10px;
    display: inline-block;
}

.inlineBlock {
    display: inline-block;
}

.dropdown-menu {
    min-width: 15rem;
    .row {
        > div {
            padding: 0;
        }
        .col-9 {
            padding-top: 3px;
            font-size: 14px;
        }
    }
    button {
        margin: 2px 0px 2px 0;
        &:nth-child(odd) {
            background-color: #cfe7ff5b;
        }
        &:hover {
            background-color: #bbddffe0;
        }
        .icon {
            font-size: 20px;
            text-align: center;
        }
        .moreDetail {
            i {
                color: #ec7631 !important;
            }
        }
        .sendMail {
            i {
                color: #1b76d3 !important;
            }
        }
        .resetPassword {
            i {
                color: #51d23a !important;
            }
        }
        .deleteUser {
            i {
                color: red !important;
            }
        }
        .editUser {
            i {
                color: #1b76d3 !important;
            }
        }
        .editRole,
        .rebootBox {
            i {
                color: #bf5eff !important;
            }
        }
        .removeRole {
            i {
                color: red !important;
            }
        }
    }
}

.btn-group {
    > .btn {
        background: none;
        color: #838383;
        border: none;
        &::after {
            content: none;
        }
        &:hover {
            background: none !important;
        }
    }

    .dropdown-menu {
        min-width: 15rem;
        .row {
            > div {
                padding: 0;
            }
            .col-9 {
                padding-top: 3px;
                font-size: 14px;
            }
        }
        button {
            margin: 2px 0px 2px 0;
            &:nth-child(odd) {
                background-color: #cfe7ff5b;
            }
            &:hover {
                background-color: #bbddffe0;
            }
            .icon {
                font-size: 20px;
                text-align: center;
            }
            .deletePlayer {
                i {
                    color: red !important;
                }
            }
            .assignedCoupon {
                i {
                    color: cadetblue !important;
                }
            }
            .playerLabel {
                color: black !important;
            }
        }
    }
}

.removeMargin {
    margin: 0 !important;
}

.addMarginForBtn {
    margin-top: -5rem !important;
}

.red { color: red !important; }
.green { color: #51d23a !important; }
.blue { color: #1b76d3 !important; }
.orange { color: #ec7631 !important; }

@media (max-width: 768px) {
    .btn-success {
        margin: 1rem !important;
    }
}

@media (max-width: 540px) {
    .main .mainLayout {
        padding: 0;
    }
}