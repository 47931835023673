@import "../../mainLayout/variables.scss";

.btn-group {
    > .btn {
        background: none;
        color: #838383;
        border: none;
        &::after {
            content: none;
        }
        &:hover {
            background: none !important;
        }
    }

    .dropdown-menu {
        min-width: 15rem;
        .row {
            > div {
                padding: 0;
            }
            .col-9 {
                padding-top: 3px;
                font-size: 14px;
            }
        }
        button {
            margin: 2px 0px 2px 0;
            &:nth-child(odd) {
                background-color: #cfe7ff5b;
            }
            &:hover {
                background-color: #bbddffe0;
            }
            .icon {
                font-size: 20px;
                text-align: center;
            }
            .removePlaylist {
                i {
                    color: #ec7631 !important;
                }
            }
            .addPlaylist {
                i {
                    color: #51d23a !important;
                }
            }
            .editPlayer {
                i {
                    color: #ec7631 !important;
                }
            }
            .deletePlayer {
                i {
                    color: #076d00 !important;
                }
            }
            .deactiveLicence{
                i {
                    color: red !important;
                }
            }
            .refreshPlayer{
                i {
                    color: #51d23a !important;
                }
            }
            .sendMessagePlayer{
                i {
                    color: #1b76d3 !important;
                }
            }
        }
    }
}

.expanableComponent {
    margin: 0 !important;
    padding: 25px;
    
    .btn-primary {
        margin-bottom: 15px;
    }
    .row.title {
        font-weight: bold;    
        border-bottom: 1px solid #cecece;
        font-size: 0.85rem;
        text-align: center;
    }
    .row.subList {
        font-size: 0.85rem;
        text-align: center;

        .col {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        .icon {
            padding-top: 8px;
        }

        &:nth-child(even) {
            background-color: #bbe9ff75;
        }
        &:hover {
            background-color: #1b76d357;
            cursor: default !important;
        }
        .top-10 {
            padding-top: 10px;
        }
    }
    .row.payment {
        &:hover {
            cursor: inherit;
        }
    }
}

.expanableComponentUser {
    margin: 0 60px 10px 60px !important;
    padding: 15px !important;
    border: 1px solid #72aae4;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    
    .blockHeight {
        max-height: 30rem;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }

    .btn-primary {
        margin-bottom: 15px;
    }
    .row.title {
        font-weight: bold;    
        border-bottom: 1px solid #cecece;
        font-size: 0.85rem;
        text-align: center;
    }
    .row.subList {
        font-size: 0.85rem;
        text-align: center;

        .col {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        .icon {
            padding-top: 8px;
        }

        &:nth-child(even) {
            background-color: #bbe9ff75;
        }
        &:hover {
            background-color: #1b76d357;
            cursor: pointer;
        }
        .top-10 {
            padding-top: 10px;
        }
    }
    .row.payment {
        &:hover {
            cursor: inherit;
        }
    }
    .info {
        font-style: italic;
        font-size: 0.8rem;
        margin-bottom: 10px;
    }
}
// end expanableComponentUser

.iconTrial,
.iconWait,
.iconLifetime,
.iconRefund,
.iconPartialRefund,
.iconSub,
.iconNotSub {
    font-size: 15px;
    margin-right: 3px;
    margin-left: 10px;
}

.iconTrial {
    color: #f35555;
}

.iconRefund {
    color: #fa3a3a;
}

.iconLifetime {
    color: #00d200;
}

.iconSub {
    color: $blue;

}

.iconNotSub,
.iconWait,
.iconPartialRefund {
    color: orange;
}

.formDetailUser {
    h4 {
        padding: 25px 0 25px 0;
    }
}