@import '../../mainLayout/variables.scss';

.loginContainer {
    .formLogin {
        position: absolute;
        width: 30%;
        background-color: white;
        text-align: center;
        padding: 4rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        box-shadow: 2px 2px 4px 0 grey;

        img {
            width: 65%;
            margin-bottom: 4rem;
        }

        .login {
            background-color: aliceblue;
            margin: 2vh 0 2vh 0;
            padding: 2vh 0 2vh 0;

            h2 {
                text-align: center;
                font-size: 20px;
            }
        }
    }

    .passwordVisibility {
        width: 100%;

    }

    .iconVisibility {
        outline: none;
    }

    .MuiInputLabel-formControl {
        left: unset !important;
    }

    // /* rimuove le frecce nell'input number*/
    // input::-webkit-outer-spin-button,
    // input::-webkit-inner-spin-button {
    //     -webkit-appearance: none;
    //     margin: 0;
    // }

    // input[type=number] {
    //     -moz-appearance: textfield;
    // }
}

@media (max-width: 1370px) {
    .loginContainer {
        .formLogin {
            width: 35%;
        }
    }
}

@media (max-width: 1090px) {
    .loginContainer {
        .formLogin {
            width: 45%;
        }
    }
}

@media (max-width: 860px) {
    .loginContainer {
        .formLogin {
            width: 55%;
        }
    }
}

@media (max-width: 768px) {
    .loginContainer {
        .imgLogin {
            width: 100%;
            margin: 15% 0 0 0;
        }

        .formLogin {
            width: 90%;
        }
    }
}

@media (max-width: 767px) {
    .loginContainer {
        .imgLogin {
            width: 100%;
            margin: 15% 0 0 0;
        }
    }
}

@media (max-width: 450px) {
    .loginContainer {
        .formLogin {
            width: 100%;
            background-color: unset;
            padding: 1rem;
            box-shadow: unset;

            img {
                display: none;
            }
        }
    }
}